import { Grid } from '@material-ui/core'
import { PlayerLadder } from '../types'
import LadderCard from './LadderCard'

interface Props {
  ladders: PlayerLadder[]
}

const LadderCardList = ({ ladders }: Props) => {
  return (
    <Grid container spacing={2}>
      {ladders.map((ladder) => (
        <Grid key={ladder.id} item xs={12} sm={6} md={4} lg={3}>
          <LadderCard ladder={ladder} />
        </Grid>
      ))}
    </Grid>
  )
}

export default LadderCardList
